import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import "../styles/about.scss"

const AboutPage = () => {
    return(
        <Layout>
            <Helmet title="About | ShortOrme Productions" />
            <div id="content">
                <h2>About</h2>
                <div class="about-block image-right">
                    <p>ShortOrme Productions is a Lincoln UK based indie film production outfit, focussing on producing short drama films. Founded in 2014 by the producers from Short Cut Films and Ormeson Film, two groups who had collaborated on a number of projects, ShortOrme productions have made four short films since then and are working on their fifth, most ambitious production, <i>Human Too.</i></p>
                </div>
            </div>
        </Layout>
    )
}

export default AboutPage
